<template>
  <!--begin::Calendar-->
  <div class="resource-calendar-template" v-if="getPermission('calendar:view')">
    <DetailTemplate customSecondClass="pt-0">
      <template v-slot:header-title>
        <h3 class="custom-header-blue-text font-size-28 text-uppercase">
          Resource Calendar
        </h3>
      </template>
      <template v-slot:header-action>
        <v-btn-toggle>
          <v-btn
            class="custom-bold-button my-2"
            v-on:click="$router.push(getDefaultRoute('calendar'))"
          >
            Calendar
          </v-btn>
          <v-btn class="custom-bold-button my-2" color="cyan white--text">
            Resource
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <div v-if="engineerLoading" class="block-ui">
            <v-progress-circular
              indeterminate
              :size="100"
              :width="8"
              color="cyan"
            ></v-progress-circular>
          </div>
          <v-row>
            <v-col md="6"> </v-col>
            <v-col md="6">
              <v-layout>
                <v-flex>
                  <v-autocomplete
                    :disabled="engineerLoading"
                    :loading="engineerLoading"
                    :items="customerList"
                    v-model="filter.customer"
                    hide-details
                    clearable
                    flat
                    solo
                    dense
                    label="Customer"
                    color="cyan"
                    item-color="cyan"
                    class="pt-0 mt-0 mr-4"
                    v-on:change="
                      getOpenDeliveries();
                      getCalendarTicket();
                    "
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex>
                  <DateRangePicker
                    :pageLoading="engineerLoading"
                    :prop_dates="initialDateRange"
                    v-model="filter.daterange"
                    v-on:change="filterDateRange"
                  ></DateRangePicker>
                </v-flex>
              </v-layout>
            </v-col>
          </v-row>
          <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 90vh; position: relative"
          >
            <v-row>
              <v-col md="9">
                <FullCalendar
                  ref="resourceCalendar"
                  :options="calendarOptions"
                />
              </v-col>
              <v-col md="3">
                <template v-if="openDeliveryLoading">
                  <h3
                    class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                    style="line-height: 64px !important"
                  >
                    <v-skeleton-loader
                      class="mb-6 mt-1"
                      type="heading"
                    ></v-skeleton-loader>
                  </h3>
                  <v-simple-table
                    class="table-bordered"
                    fixed-header
                    dense
                    height="535px"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr v-for="i in 10" :key="i">
                          <td class="simple-table-th font-size-16" width="100">
                            <v-skeleton-loader
                              class="custom-skeleton py-2 my-0"
                              type="text"
                            ></v-skeleton-loader>
                          </td>
                          <td class="simple-table-th font-size-16">
                            <v-skeleton-loader
                              class="py-2 my-0"
                              type="sentences"
                            ></v-skeleton-loader>
                          </td>
                        </tr>
                      </thead>
                    </template>
                  </v-simple-table>
                </template>
                <template v-else>
                  <template v-if="openDeliveries.length > 0">
                    <h3
                      class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                      style="line-height: 52px !important"
                    >
                      ({{ openDeliveries.length }}) Open Deliveries
                    </h3>
                    <v-simple-table
                      class="table-bordered"
                      fixed-header
                      dense
                      id="external-draggable"
                      height="535px"
                    >
                      <template v-slot:default>
                        <tbody>
                          <template v-for="(visit, index) in openDeliveries">
                            <tr
                              :key="index"
                              class="fc-event"
                              :id="`un-assigned-visit-${visit.id}`"
                              :data-event="JSON.stringify(visit)"
                            >
                              <td class="simple-table-td" width="100">
                                <Barcode :barcode="visit.barcode"></Barcode>
                                <p>{{ visit.title }}</p>
                                <p>
                                  {{ formatDateTime(visit.started_at) }} -
                                  {{ formatTime(visit.finished_at) }}
                                </p>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                  <template v-else>
                    <h3
                      class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                      style="line-height: 52px !important"
                    >
                      No Open Deliveries
                    </h3>
                  </template>
                </template>
              </v-col>
            </v-row>
          </perfect-scrollbar>
        </v-container>
        <Dialog :commonDialog="eventResizedDialog">
          <template v-slot:title> Confirmation </template>
          <template v-slot:body>
            <v-row>
              <v-col md="12" class="pt-0">
                <h3 class="font-size-16 font-weight-600 delete-text">
                  Are you sure you want to re-schedule visit #{{
                    visit.barcode
                  }}
                  ?
                </h3>
              </v-col>
              <v-col
                md="6"
                class="delete-text font-weight-500 custom-border-right"
              >
                <label class="custom-border-bottom width-100"
                  >Previous Schedule</label
                >
                <table class="width-100">
                  <tbody>
                    <tr>
                      <td>Started At</td>
                      <td>{{ formatDateTime(visit.previous_started_at) }}</td>
                    </tr>
                    <tr>
                      <td>Finished At</td>
                      <td>{{ formatDateTime(visit.previous_finished_at) }}</td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col md="6" class="delete-text font-weight-500">
                <label class="custom-border-bottom width-100"
                  >New Schedule</label
                >
                <table class="width-100">
                  <tbody>
                    <tr>
                      <td>Started At</td>
                      <td>{{ formatDateTime(visit.new_started_at) }}</td>
                    </tr>
                    <tr>
                      <td>Finished At</td>
                      <td>{{ formatDateTime(visit.new_finished_at) }}</td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col md="12" class="mt-4">
                <b>Note</b>:
                <em
                  >All engineers will be removed from visit #{{
                    visit.barcode
                  }}</em
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="rescheduleDelivery"
            >
              Yes
            </v-btn>
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="closeEventResized"
            >
              No
            </v-btn>
          </template>
        </Dialog>
        <Dialog :commonDialog="eventDroppedDialog" :dialogWidth="dialogWidth">
          <template v-slot:title> Confirmation </template>
          <template v-slot:body>
            <v-row>
              <v-col md="12" class="pt-0">
                <h3 class="font-size-16 font-weight-600 delete-text">
                  Are you sure you want to re-assign visit #{{
                    visit.new_visit_barcode
                  }}
                  - {{ visit.new_visit_title }}
                  ?
                </h3>
              </v-col>
              <v-col
                md="6"
                class="delete-text font-weight-500 custom-border-right"
              >
                <label class="custom-border-bottom width-100"
                  >Previous Engineer</label
                >
                <table class="width-100">
                  <tbody
                    v-if="lodash.isEmpty(visit.previous_engineer) === false"
                  >
                    <tr>
                      <td>Engineer #</td>
                      <td>{{ visit.previous_engineer.barcode }}</td>
                    </tr>
                    <tr>
                      <td>Engineer Name</td>
                      <td>{{ visit.previous_engineer.full_name }}</td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col md="6" class="delete-text font-weight-500">
                <label class="custom-border-bottom width-100"
                  >New Engineer</label
                >
                <table class="width-100">
                  <tbody v-if="lodash.isEmpty(visit.new_engineer) === false">
                    <tr>
                      <td>Engineer #</td>
                      <td>{{ visit.new_engineer.barcode }}</td>
                    </tr>
                    <tr>
                      <td>Engineer Name</td>
                      <td>{{ visit.new_engineer.full_name }}</td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col
                md="12"
                class="mt-4"
                v-if="lodash.isEmpty(visit.previous_engineer) === false"
              >
                <b>Note</b>:
                <em
                  >Engineer #{{ visit.previous_engineer.barcode }} -
                  {{ visit.new_engineer.full_name }} will be removed from visit
                  #{{ visit.new_visit_barcode }} -
                  {{ visit.new_visit_title }}</em
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="pageLoading"
              :loading="pageLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="reAllocateEngineer"
            >
              Yes
            </v-btn>
            <v-btn
              :disabled="pageLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="closeEventDropped"
            >
              No
            </v-btn>
          </template>
        </Dialog>
        <template v-if="getPermission('visit:view') && eventClickedDialog">
          <SingleDeliveryDetail
            :detail="visitDetail.detail"
            :visit="visitDetail.visit_id"
            :customer="visitDetail.customer"
            :property="visitDetail.property"
            :billing="visitDetail.billing"
            :contactPerson="visitDetail.contactPerson"
            :pageLoading="pageLoading"
            v-on:schedule:updated="visitScheduleUpdated"
          ></SingleDeliveryDetail>
        </template>
      </template>
    </DetailTemplate>
  </div>
  <!--end::Calendar-->
</template>

<script>
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import FullCalendar from "@fullcalendar/vue";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { GET, QUERY, PATCH } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
// import Datepicker from "@/view/pages/partials/Datepicker.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import SingleDeliveryDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import DateRangePicker from "@/view/pages/partials/DateRangePicker";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "resource-calendar",
  mixins: [CommonMixin, JobRequestMixin],
  components: {
    DetailTemplate,
    FullCalendar,
    Barcode,
    Dialog,
    SingleDeliveryDetail,
    DateRangePicker,
    // Datepicker
  },
  data() {
    return {
      engineerLoading: true,
      openDeliveryLoading: true,
      eventResizedDialog: false,
      eventDroppedDialog: false,
      eventClickedDialog: false,
      eventLoading: true,
      pageLoading: false,
      calendar: null,
      visitDetail: new Object({
        detail: null,
        visit_id: null,
        customer: null,
        property: null,
        billing: null,
        contactPerson: null,
      }),
      visit: {
        id: null,
        oldId: null,
        engineerId: null,
        barcode: null,
        engineer_barcode: null,
        previous_visit_barcode: null,
        previous_visit_title: null,
        previous_status_text: null,
        previous_started_at: null,
        previous_finished_at: null,
        previous_engineer: null,
        previous_visit: null,
        new_visit: null,
        new_engineer: null,
        new_visit_barcode: null,
        new_visit_title: null,
        new_status_text: null,
        new_started_at: null,
        new_finished_at: null,
      },
      filter: {
        customer: null,
        daterange: [
          moment().startOf("day").format("YYYY-MM-DD"),
          moment().endOf("day").format("YYYY-MM-DD"),
        ],
      },
      openDeliveries: [],
      ticketList: [],
      calendarOptions: {
        plugins: [resourceTimelinePlugin, interactionPlugin],
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        headerToolbar: {
          left: "prev today next",
          center: "title",
          right:
            "resourceTimelineDay,resourceTimelineThreeDay,resourceTimelineWeek,resourceTimelineFifteenDay,resourceTimelineThirtyDay",
        },
        aspectRatio: 2.2,
        initialView: "resourceTimelineDay",
        views: {
          resourceTimelineThreeDay: {
            type: "resourceTimeline",
            duration: { days: 3 },
            buttonText: "3 days",
          },
          resourceTimelineFifteenDay: {
            type: "resourceTimeline",
            duration: { days: 15 },
            buttonText: "15 days",
          },
          resourceTimelineThirtyDay: {
            type: "resourceTimeline",
            duration: { days: 30 },
            buttonText: "30 days",
          },
        },
        initialDate: moment().startOf("day").format("YYYY-MM-DD"),
        timeZone: process.env.VUE_APP_TIMEZONE,
        scrollTime: "08:00",
        editable: false,
        selectable: false,
        droppable: false,
        nowIndicator: true,
        eventResize: this.eventResized,
        eventDrop: this.eventDropped,
        eventClick: this.eventClicked,
        drop: this.dropped,
        events: this.getCalendarEvents,
        datesSet: this.dateChanged,
        resourceAreaWidth: "20%",
        resourceAreaColumns: [
          {
            headerContent: "Engineer Name",
            field: "full_name",
          },
        ],
        resources: this.getResources,
      },
      customerList: [],
      resizedEvent: {},
      droppedEvent: {},
    };
  },
  mounted() {
    const _this = this;
    _this.getOpenDeliveries();
    _this.getCalendarCustomer();
    _this.getCalendarTicket();
    _this.$store.dispatch(SET_BREADCRUMB, [{ title: "Resource Calendar" }]);
    _this.calendar = this.$refs["resourceCalendar"].getApi();
    if (_this.getPermission("calendar:update")) {
      _this.calendar.setOption("editable", true);
      _this.calendar.setOption("selectable", true);
      _this.calendar.setOption("droppable", true);
    }
  },
  beforeDestroy() {
    this.calendar.destroy();
  },
  methods: {
    filterDateRange() {
      if (
        this.filter.daterange &&
        this.lodash.isArray(this.filter.daterange) &&
        this.filter.daterange.length === 2
      ) {
        const startDate = moment(this.filter.daterange[0]);
        const endDate = moment(this.filter.daterange[1]);
        if (moment(endDate).isAfter(startDate)) {
          this.getOpenDeliveries();
        }
      }
    },
    visitScheduleUpdated() {
      setTimeout(function () {
        window.location.reload();
      }, 500);
    },
    loadContent(visitId, jobId) {
      const _this = this;
      _this.pageLoading = true;
      _this
        .getJob(jobId)
        .then(({ ticket, customer, property, billing, contact_person }) => {
          _this.visitDetail = new Object({
            detail: ticket || new Object(),
            visit_id: visitId,
            customer: customer || new Object(),
            property: property || new Object(),
            billing: billing || new Object(),
            contactPerson: contact_person || new Object(),
          });
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.eventClickedDialog = true;
          _this.pageLoading = false;
        });
    },
    updateFilterDate(date) {
      if (this.calendar && date) {
        this.calendar.gotoDate(date);
      }
      this.getOpenDeliveries();
    },
    initExternalDraggable() {
      const _this = this;
      if (_this.getPermission("calendar:update")) {
        let containerEl = document.getElementById("external-draggable");
        new Draggable(containerEl, {
          itemSelector: ".fc-event",
          eventData: function (eventEl) {
            try {
              if (eventEl.dataset) {
                let event = JSON.parse(eventEl.dataset.event);
                return {
                  title: event.barcode + " | " + event.full_name,
                };
              }
            } catch (error) {
              return {
                title: eventEl.innerText,
              };
            }
          },
        });
      }
    },
    eventResized(eventResized) {
      try {
        this.resizedEvent = eventResized;
        let event = eventResized.event;
        let oldEvent = eventResized.oldEvent;
        this.visit = new Object({
          engineer_barcode: null,
          engineerId: null,
          previous_visit_barcode: null,
          previous_visit_title: null,
          previous_status_text: null,
          new_visit_barcode: null,
          new_visit_title: null,
          new_status_text: null,
          previous_engineer: null,
          previous_visit: null,
          new_visit: null,
          new_engineer: null,
          oldId: null,
          id: event.extendedProps.visitId,
          barcode: event.extendedProps.visitBarcode,
          previous_started_at: oldEvent.startStr.substr(0, 19),
          previous_finished_at: oldEvent.endStr.substr(0, 19),
          new_started_at: event.startStr.substr(0, 19),
          new_finished_at: event.endStr.substr(0, 19),
        });
        this.eventResizedDialog = true;
      } catch (error) {
        this.logError(error);
        eventResized.revert();
      }
    },
    rescheduleDelivery() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "visit/" + _this.visit.id + "/re-schedule",
          data: {
            date: moment(_this.visit.new_started_at).format("YYYY-MM-DD"),
            start_time: moment(_this.visit.new_started_at).format("HH:mm"),
            end_time: moment(_this.visit.new_finished_at).format("HH:mm"),
          },
        })
        .then(() => {
          _this.eventResizedDialog = false;
          _this.resizedEvent = new Object({});
        })
        .catch((error) => {
          _this.logError(error);
          _this.resizedEvent.revert();
          _this.resizedEvent = new Object({});
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.reloadCalendar();
          _this.getOpenDeliveries();
        });
    },
    reAllocateEngineer() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "calendar/re-assign-visit",
          data: _this.visit,
        })
        .then(() => {
          _this.eventDroppedDialog = false;
          _this.droppedEvent = new Object({});
        })
        .catch((error) => {
          _this.logError(error);
          _this.droppedEvent.revert();
          _this.droppedEvent = new Object({});
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.reloadCalendar();
        });
    },
    closeEventResized() {
      this.eventResizedDialog = false;
      if (this.lodash.isEmpty(this.resizedEvent) === false) {
        this.resizedEvent.revert();
        this.resizedEvent = new Object({});
      }
      this.visit = new Object({
        id: null,
        oldId: null,
        engineerId: null,
        barcode: null,
        engineer_barcode: null,
        previous_visit_barcode: null,
        previous_visit_title: null,
        previous_status_text: null,
        previous_started_at: null,
        previous_finished_at: null,
        previous_visit: null,
        new_visit: null,
        previous_engineer: null,
        new_engineer: null,
        new_visit_barcode: null,
        new_visit_title: null,
        new_status_text: null,
        new_started_at: null,
        new_finished_at: null,
      });
    },
    eventDropped(eventDropped) {
      try {
        this.droppedEvent = eventDropped;
        const { event, oldEvent, newResource, oldResource } = eventDropped;
        this.visit = new Object({
          id: newResource.id,
          oldId: oldResource.id,
          engineerId: newResource.id,
          barcode: null,
          engineer_barcode: newResource.extendedProps.barcode,
          previous_visit: oldEvent.extendedProps,
          previous_engineer: oldResource.extendedProps,
          previous_visit_barcode: oldEvent.extendedProps.visitBarcode,
          previous_visit_title: oldEvent.extendedProps.visitTitle,
          previous_status_text: oldEvent.extendedProps.statusText,
          previous_started_at: oldEvent.extendedProps.startedAt,
          previous_finished_at: oldEvent.extendedProps.finishedAt,
          new_engineer: newResource.extendedProps,
          new_visit: event.extendedProps,
          new_visit_barcode: event.extendedProps.visitBarcode,
          new_visit_title: event.extendedProps.visitTitle,
          new_status_text: event.extendedProps.statusText,
          new_started_at: event.extendedProps.startedAt,
          new_finished_at: event.extendedProps.finishedAt,
        });
        this.eventDroppedDialog = true;
      } catch (error) {
        this.logError(error);
        eventDropped.revert();
      }
    },
    closeEventDropped() {
      this.eventDroppedDialog = false;
      if (this.lodash.isEmpty(this.droppedEvent) === false) {
        this.droppedEvent.revert();
        this.droppedEvent = new Object({});
      }
      this.visit = new Object({
        id: null,
        oldId: null,
        engineerId: null,
        barcode: null,
        engineer_barcode: null,
        previous_visit_barcode: null,
        previous_visit_title: null,
        previous_status_text: null,
        previous_started_at: null,
        previous_finished_at: null,
        previous_engineer: null,
        previous_visit: null,
        new_visit: null,
        new_engineer: null,
        new_visit_barcode: null,
        new_visit_title: null,
        new_status_text: null,
        new_started_at: null,
        new_finished_at: null,
      });
    },
    eventClicked({ event }) {
      const { extendedProps } = event;
      this.loadContent(extendedProps.visitId, extendedProps.ticketId);
    },
    dateChanged({ startStr, endStr }) {
      this.filter.daterange = [
        moment(startStr).format("YYYY-MM-DD"),
        moment(endStr).format("YYYY-MM-DD"),
      ];
      this.reloadCalendar();
      this.getOpenDeliveries();
    },
    dropped({ resource, draggedEl }) {
      const _this = this;
      const { dataset } = draggedEl;
      const visit = JSON.parse(dataset.event);
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "assign-engineer-by-date",
          data: {
            started_at: visit.started_at,
            finished_at: visit.finished_at,
            visit: visit.id,
            engineer: resource.id,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.reloadCalendar();
        });
    },
    reloadCalendar() {
      try {
        if (this.calendar) {
          this.calendar.removeAllEvents();
          this.calendar.refetchResources();
          this.calendar.refetchEvents();
        }
      } catch (error) {
        this.logError(error);
        window.location.reload(true);
      }
    },
    getCalendarCustomer() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "calendar/filter-customer",
        })
        .then(({ data }) => {
          _this.customerList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCalendarTicket() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "calendar/filter-ticket",
          data: _this.filter,
        })
        .then(({ data }) => {
          _this.ticketList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCalendarEvents(fetchInfo, successCallback, failureCallback) {
      const _this = this;
      _this.eventLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "calendar/resource-events",
          data: _this.filter,
        })
        .then(({ data }) => {
          successCallback(data);
        })
        .catch((error) => {
          failureCallback(error);
        })
        .finally(() => {
          _this.eventLoading = false;
        });
    },
    getOpenDeliveries() {
      const _this = this;
      _this.openDeliveryLoading = true;
      if (_this.lodash.isEmpty(_this.filter) === false) {
        _this.$store
          .dispatch(QUERY, {
            url: "calendar/open-visits",
            data: _this.filter,
          })
          .then(({ data }) => {
            _this.openDeliveries = data;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.openDeliveryLoading = false;
            if (_this.openDeliveries && _this.openDeliveries.length > 0) {
              setTimeout(_this.initExternalDraggable, 200);
            }
          });
      }
    },
    getResources(fetchInfo, successCallback, failureCallback) {
      const _this = this;
      _this.engineerLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "calendar/working-engineers",
        })
        .then(({ data }) => {
          successCallback(data);
        })
        .catch((error) => {
          failureCallback(error);
        })
        .finally(() => {
          _this.engineerLoading = false;
        });
    },
  },
  computed: {
    initialDateRange() {
      const dateRange = this.lodash.cloneDeep(this.filter.daterange);
      if (
        dateRange &&
        this.lodash.isArray(dateRange) &&
        dateRange.length === 2
      ) {
        const startDate = moment(dateRange[0]);
        const endDate = moment(dateRange[1]);
        if (moment(endDate).isAfter(startDate)) {
          return dateRange;
        }
      }
      return [];
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
